import {MDCCheckbox} from '@material/checkbox';
import {MDCDrawer} from "@material/drawer";
import {MDCFormField} from '@material/form-field';
import {MDCList} from "@material/list";
import {MDCMenu} from '@material/menu';
import {MDCMenuSurface} from '@material/menu-surface';
import {MDCRipple} from '@material/ripple';
import {MDCSelect} from '@material/select';
import {MDCSnackbar} from '@material/snackbar';
import {MDCTopAppBar} from '@material/top-app-bar';
import {MDCTabBar} from '@material/tab-bar';
import {MDCTextField} from '@material/textfield';


// MDCTextFields
const textFieldEls = document.querySelectorAll('.mdc-text-field');
textFieldEls.forEach(textFieldEl => new MDCTextField(textFieldEl));

const mainContentEl = document.querySelector('.main-content');

// Initialize drawer
const drawerEl = document.querySelector('.mdc-drawer');
var drawer = null;
if (drawerEl){
    drawer = new MDCDrawer(drawerEl);
    drawer.open = false;
}

// MDCTopAppBar
const baseTopAppBarEl = document.querySelector('.base-top-app-bar');
const loginTopAppBarEl = document.querySelector('.login-top-app-bar');
if (baseTopAppBarEl){
    const baseTopAppBar = new MDCTopAppBar(baseTopAppBarEl);
    baseTopAppBar.setScrollTarget(mainContentEl); // Stylize top app bar on scrolling main-content
    baseTopAppBar.listen('MDCTopAppBar:nav', () => {
        drawer.open = !drawer.open;
    });
}
if (loginTopAppBarEl){
    const loginTopAppBar = new MDCTopAppBar(loginTopAppBarEl);
    loginTopAppBar.setScrollTarget(mainContentEl); // Stylize top app bar on scrolling main-content
}

const navIconEl = document.querySelector('.mdc-top-app-bar__navigation-icon');

// Initialize tab bar
const tabBarEls = document.querySelectorAll('.mdc-tab-bar');
tabBarEls.forEach(function(tabBarEl){
    new MDCTabBar(tabBarEl);
    // Switch content on tab or drawer item activation
    tabBarEl.addEventListener('MDCTabBar:activated', (e) => switchToSection(e.detail.index));
});
const tabEls = Array.from(document.querySelectorAll('.mdc-tab'));


// Top app bar menus
const databaseTabEl = document.querySelector('#database-tab');
const mlTabEl = document.querySelector('#ml-tab');
const profileTabEl = document.querySelector('#profile-tab');
const contentTabEl = document.querySelector('#content-tab');
//const mdcMenuSurfaces = document.querySelectorAll('.mdc-menu-surface');
//mdcMenuSurfaces.forEach(mdcMenuSurface => new MDCMenuSurface(mdcMenuSurface));

if (mlTabEl){
    const mlMenu = new MDCMenuSurface(document.querySelector('#ml-menu'));
    // Open the database menu when the database menu is clicked
    mlTabEl.addEventListener('click', function(){
        if ($('#ml-menu').hasClass('mdc-menu-surface--open')) {
            mlMenu.close();
        } else {
            var x = $('#ml-tab').position().left;
            mlMenu.setAbsolutePosition(x, 0);
            mlMenu.open();
        }
    });   
}

if (databaseTabEl){
    const databaseMenu = new MDCMenuSurface(document.querySelector('#database-menu'));
    // Open the database menu when the database menu is clicked
    databaseTabEl.addEventListener('click', function(){
        if ($('#database-menu').hasClass('mdc-menu-surface--open')) {
            databaseMenu.close();
        } else {
            var x = $('#database-tab').position().left;
            databaseMenu.setAbsolutePosition(x, 0);
            databaseMenu.open();
        }
    });
}

if (profileTabEl){
    const profileMenu = new MDCMenuSurface(document.querySelector('#profile-menu'));
    // Open the profile menu when the profile tab is clicked
    profileTabEl.addEventListener('click', function(){
        if ($('#profile-menu').hasClass('mdc-menu-surface--open')) {
            profileMenu.close();
        } else {
            var x = $('#profile-tab').position().left;
            profileMenu.setAbsolutePosition(x, 0);
            profileMenu.open();
        }
    });
}

if (contentTabEl){
    const contentMenu = new MDCMenuSurface(document.querySelector('#content-menu'));
    // Open the content menu when the content tab is clicked
    contentTabEl.addEventListener('click', function(){
        if ($('#content-menu').hasClass('mdc-menu-surface--open')) {
            contentMenu.close();
        } else {
            var x = $('#content-tab').position().left;
            contentMenu.setAbsolutePosition(x, 0);
            contentMenu.open();
        }
    });
}

const listEl = document.querySelector('.mdc-drawer .mdc-list');
if (listEl){
    listEl.wrapFocus = true;
    listEl.addEventListener('click', (event) => {
      drawer.open = false;
    });
    // Update to e.detail.index on next release: https://github.com/material-components/material-components-web/pull/4356
    listEl.addEventListener('MDCList:action', (e) => switchToSection(e.detail));
}

const list = new MDCList(document.querySelector('.mdc-list'));

const contentEls = Array.from(document.querySelectorAll('.content-section'));

const switchToSection = (index) => {
  contentEls.forEach(contentSection => {
    contentSection.style.display = getSectionName(contentSection, '-content') == getSectionName(tabEls[index], '-tab') ? 'block' : 'none';
  });
  
  // Make sure to update whichever one was not clicked on
  drawer.list_.selectedIndex = index; // https://github.com/material-components/material-components-web/issues/4364
  tabBar.activateTab(index);
}

const getSectionName = (el, sectionSuffix) => el.id.slice(0, -1 * sectionSuffix.length);

// Checkboxes
const checkBoxEls = document.querySelectorAll('.mdc-checkbox');
checkBoxEls.forEach(checkBoxEl => new MDCCheckbox(checkBoxEl));

// Form fields
const formFieldEls = document.querySelectorAll('.mdc-form-field');
formFieldEls.forEach(formFieldEl => new MDCFormField(formFieldEl));

// Text field icons
const textFieldIconEls = document.querySelectorAll('.mdc-text-field__icon');
textFieldIconEls.forEach(textFieldIconEl => new MDCTextFieldIcon(textFieldIconEl));

// Attach ripple to buttons
const buttonEls = document.querySelectorAll('.mdc-button');
buttonEls.forEach(buttonEl => new MDCRipple(buttonEl));

/*---Snackbar---*/
const snackbar = new MDCSnackbar(document.querySelector('#snackbar-div .mdc-snackbar'));
export function snackbar_message(message){
    console.log(message);
    $('#snackbar-div .mdc-snackbar__label').html(message);
    snackbar.open();
}
